/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const validMap = ['admin', 'editor']
  return validMap.indexOf(str.trim()) >= 0
}

export function hanleTimeDelay(rule, time, callback) {
  if (time === null || time === '') {
    console.log('发表时间为空')
    return callback()
  }
  // 校验是否大于当前时间+1分钟
  const nowDate = new Date()
  const nowTimestamp = nowDate.getTime()
  const ruleTime = new Date(time)
  const ruleTimestamp = ruleTime.getTime()
  if (ruleTimestamp - nowTimestamp > 60 * 1000 * 1) {
    return callback()
  }
  callback(new Error('发帖时间不能小于当前时间'))
}
