<template>
  <el-dialog title="高风险推文" :visible.sync="dialogVisible" width="50%">
    <div style="color:red;margin-bottom:20px;margin-left:10px;font-size: 1rem;">
      发表高风险推文,会导致帐号封禁概率急剧增加,请谨慎抉择
    </div>
    <div
      v-for="(item, index) in this.tweetsList"
      :key="index"
      style="margin:10px"
    >
      {{ index + 1 }}: {{ item }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getExceptionTwitterPostTasks } from '@/api/twitter'

export default {
  name: 'ExceptionPostTasks',
  data() {
    return {
      dialogVisible: false,
      tweetsList: []
    }
  },
  methods: {
    async fetchData() {
      const { items } = await getExceptionTwitterPostTasks()
      // console.log('ExceptionPostTasks', data)
      const tweets = []
      for (let i = 0; i < items.length; ++i) {
        tweets[i] = items[i].content
      }
      this.tweetsList = tweets
    }
  },
  async created() {
    await this.fetchData()
    this.dialogVisible = true
  }
}
</script>
