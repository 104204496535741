import request from '@/plugins/axios'

export function authLogin(data) {
  // 获取twitter帐号列表
  return request({
    url: '/authentication/auth_login',
    method: 'post',
    data
  })
}

export function login(params) {
  // 获取twitter帐号列表
  return request({
    url: '/authentication/login',
    method: 'post',
    data: params
  })
}

export function logOut() {
  return request({
    url: '/authentication/logout',
    method: 'post'
  })
}

export function loginUserInfo() {
  return request({
    url: '/user/loginUserInfo',
    method: 'get'
  })
}

export function userPage(page, limit, user) {
  return request({
    url: `/user/${page}/${limit}`,
    params: user,
    method: 'get'
  })
}

export function getUsers(params) {
  return request({
    url: 'authentication/users',
    method: 'get',
    params
  })
}
export function updateUser(userId, data) {
  return request({
    url: `authentication/users/${userId}`,
    method: 'patch',
    data
  })
}

export function addUser(user) {
  return request({
    url: '/user/save',
    method: 'post',
    data: user
  })
}

export function updateColumn(id, column, value) {
  return request({
    url: `/user/updateColumn/${id}/${column}/${value}`,
    method: 'put'
  })
}

export function resetPwd(id) {
  return request({
    url: `/user/resetPwd/${id}`,
    method: 'put'
  })
}

export function updateUserInfo() {
  return request({
    url: '/user/updateBatchUserInfo',
    method: 'put'
  })
}

export function getStorageInfo() {
  return request({
    url: '/material/storage',
    method: 'get'
  })
}
