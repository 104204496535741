<template>
  <el-dialog
    title="选择语料"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    append-to-body
  >
    <el-form :model="form" ref="formName" :rules="rules" label-width="100px">
      <el-form-item prop="text" label="语料">
        <el-select
          style="width:80%;"
          filterable
          clearable
          value-key="id"
          v-model="form.text"
          placeholder="请选择语料"
          @change="changeText"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.text"
            :value="item"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="译文" v-if="form.text && form.text.translation">
        {{ form.text.translation }}
      </el-form-item>
      <el-form-item label="使用译文" style="margin-top: 10px;">
        <el-checkbox v-model="form.checked"></el-checkbox>
      </el-form-item>
      <el-form-item style="text-align: right;margin-top: 30px;">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { listApi } from '@/api/language'

export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      options: [],
      rules: {
        text: [{ required: true, message: '请选择语料', trigger: 'change' }]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await listApi({
        pageIndex: 1,
        pageSize: 10000
      })

      this.options = items.map(item => {
        if (!item.translation) {
          // item.disabled = true
        }
        return item
      })
    },
    initFun() {
      if (this.$refs['formName']) {
        this.$refs['formName'].resetFields()
      }
      this.form = {
        checked: false
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    onSubmit() {
      this.$refs['formName'].validate(valid => {
        if (valid) {
          let text = ''
          if (this.form.checked) {
            if (!this.form.text.translation) {
              return this.$message.warning('该语料无译文，请选择其他语料！')
            } else {
              text = this.form.text.translation
            }
          } else {
            text = this.form.text.text
          }
          this.$emit('languageFun', text)
          this.dialogVisible = false
        }
      })
    },
    changeText(data) {}
  }
}
</script>
