import request from '@/plugins/axios'
// 语料列表
export function listApi(params) {
  return request({
    url: '/material/corpus',
    method: 'get',
    params
  })
}
// 创建
export function addApi(data) {
  return request({
    url: '/material/corpus',
    method: 'post',
    data
  })
}
// 获取指定语料详情
export function detailApi(id) {
  return request({
    url: `/material/corpus/${id}`,
    method: 'get'
  })
}
// 删除
export function delApi(id) {
  return request({
    url: `/material/corpus/${id}`,
    method: 'delete'
  })
}
// 翻译指定
export function translateApi(id, data) {
  return request({
    url: `/material/corpus/${id}:translate`,
    method: 'post',
    data
  })
}
