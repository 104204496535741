import request from '@/plugins/axios'

// ================推特帐号接口==============
export function getAccountList(params) {
  // 获取twitter帐号列表
  return request({
    url: '/twitter/accounts',
    method: 'get',
    params
  })
}
export function getAccountAll(params) {
  // 获取twitter帐号列表
  return request({
    url: '/twitter/accounts:get_all',
    method: 'get',
    params
  })
}

export function updateAccount(accountId, data) {
  // 更新twitter帐号信息
  return request({
    url: `/twitter/accounts/${accountId}`,
    method: 'patch',
    data
  })
}

// ==============帐号控制权限接口===================
export function getAssignByUser(userId, params) {
  // 获取某用户控制的推特帐号
  return request({
    url: `/twitter/accountAssigns/user/${userId}`,
    method: 'get',
    params
  })
}

export function addAssignAccount(userId, accountId) {
  // 给某用户增加某账号的控制权限
  return request({
    url: 'twitter/accountAssigns',
    method: 'post',
    data: {
      userId,
      accountId
    }
  })
}

export function deleteAssignAccount(userId, accountId) {
  // 删除某用户的某账号的控制权限
  return request({
    url: 'twitter/accountAssigns',
    method: 'delete',
    data: {
      userId,
      accountId
    }
  })
}

export function getAssignByAccount(accountId, params) {
  // 获取某twitter帐号 对应的使用者
  return request({
    url: `/twitter/accountAssigns/account/${accountId}`,
    method: 'get',
    params
  })
}

// ================发帖任务接口==============
export function getPostTaskList(params) {
  // 获取twitter发帖任务列表
  return request({
    url: '/twitter/post_tasks',
    method: 'get',
    params
  })
}

export function getPostTaskScreenshot(id) {
  // 获取twitter发帖任务的截图
  return request({
    url: `/twitter/postTasks/${id}/screenshot`,
    method: 'get'
  })
}

export function addPostTask(params) {
  const data = {}
  Object.keys(params).forEach(key => {
    if (
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ''
    ) {
      data[key] = params[key]
    }
  })
  // 新增twitter发帖任务
  return request({
    url: '/twitter/post_tasks',
    method: 'post',
    data: data
  })
}

export function delPostTaskByIds(ids) {
  // 批量删除发帖任务
  return request({
    url: 'twitter/post_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}
export function confirmPostTaskRiskType(id, riskType) {
  // 批量删除发帖任务
  return request({
    url: `twitter/post_tasks/${id}:confirm_risk`,
    method: 'patch',
    data: { risk_type: riskType }
  })
}

export function getExceptionTwitterPostTasks() {
  // 批量删除发帖任务
  return request({
    url: 'twitter/risk_post_task',
    method: 'get'
  })
}

// ================转帖任务接口==============
export function getForwardTaskList(params) {
  // 获取转帖任务列表
  return request({
    url: '/twitter/forward_tasks',
    method: 'get',
    params
  })
}

export function addForwardTask(params) {
  const data = {}
  Object.keys(params).forEach(key => {
    if (
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ''
    ) {
      data[key] = params[key]
    }
  })
  // 新增twitter转帖任务
  return request({
    url: '/twitter/forward_tasks',
    method: 'post',
    data: data
  })
}

export function delForwardTaskByIds(ids) {
  // 批量删除发帖任务
  return request({
    url: 'twitter/forward_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}

// ========评论任务接口===========
export function getCommentTask(params) {
  //  获取评论任务列表
  return request({
    url: 'twitter/comment_tasks',
    method: 'get',
    params
  })
}

export function addCommentTask(params) {
  // 添加评论任务
  return request({
    url: '/twitter/comment_tasks',
    method: 'post',
    data: params
  })
}

export function delCommentTaskByIds(ids) {
  // 批量删除发帖任务
  return request({
    url: 'twitter/comment_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}

// ========点赞任务==========
export function addLikeTask(params) {
  // 添加点赞任务
  return request({
    url: '/twitter/like_tasks',
    method: 'post',
    data: params
  })
}

export function getLikeTask(params) {
  //  获取点赞任务列表
  return request({
    url: 'twitter/like_tasks',
    method: 'get',
    params
  })
}

export function delLikeTaskByIds(ids) {
  // 批量删除发帖任务
  return request({
    url: 'twitter/like_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}

export function getTwitterDemoAccount() {
  // 获取推特的演示帐号
  return request({
    url: 'twitter/demoAccounts/list',
    method: 'get'
  })
}

export function addTwitterDemoAccount(accountId) {
  // 获取推特的演示帐号
  return request({
    url: 'twitter/demoAccounts/add',
    method: 'post',
    data: {
      accountId: accountId
    }
  })
}

export function deleteTwitterDemoAccount(accountId) {
  // 获取推特的演示帐号
  return request({
    url: `twitter/demoAccounts/del/${accountId}`,
    method: 'delete'
  })
}

export function getRiskKeyword(params) {
  // 分页获取风险关键词
  return request({
    url: '/twitter/risk_keywords',
    method: 'get',
    params
  })
}

export function getAllRiskKeyword(params) {
  // 分页获取风险关键词
  return request({
    url: '/twitter/risk_keywords:get_all',
    method: 'get',
    params
  })
}
export function addRiskKeyword(keyword) {
  // 新增风险关键词
  return request({
    url: '/twitter/risk_keywords',
    method: 'post',
    data: {
      keyword
    }
  })
}
export function deleteRiskKeyword(id) {
  // 新增风险关键词
  return request({
    url: `/twitter/risk_keywords/${id}`,
    method: 'delete'
  })
}

// ===============订单帐号==================
export function getAccountOrderList(params) {
  // 分页订单帐号
  return request({
    url: '/twitter/account_orders',
    method: 'get',
    params
  })
}
// ========举报任务==========
export function reportTask(params) {
  // 分页
  return request({
    url: '/twitter/report_tasks',
    method: 'get',
    data: params
  })
}
export function addReportTask(data) {
  // 新增
  return request({
    url: '/twitter/report_tasks',
    method: 'post',
    data
  })
}
export function delReportTask(id) {
  // 删除
  return request({
    url: `/twitter/report_tasks/${id}`,
    method: 'delete'
  })
}
export function batch_delReportTask(data) {
  // 批量删除
  return request({
    url: `/twitter/report_tasks:batch_delete`,
    method: 'post',
    data
  })
}
export function uploadReportTask(data) {
  // 批量上传
  return request({
    url: `/twitter/report_tasks:batch_upload`,
    method: 'post',
    data
  })
}
// ================账号提交==============
export function submit_api(params) {
  //获取twitter提交账号列表
  return request({
    url: '/twitter/submit_accounts',
    method: 'get',
    params
  })
}
export function submit_add_api(data) {
  //提交twitter账号
  return request({
    url: '/twitter/submit_accounts',
    method: 'post',
    data
  })
}
export function submit_detail_api(id) {
  // twitter账号详情
  return request({
    url: `/twitter/submit_accounts/${id}`
  })
}
export function submit_del_api(id) {
  // twitter账号删除
  return request({
    url: `/twitter/submit_accounts/${id}`,
    method: 'delete'
  })
}
