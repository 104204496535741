import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/twitter'
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/twitter',
    component: Layout,
    // alias: '/twitter',
    name: 'Twitter',
    redirect: '/twitter/account',
    meta: {
      title: 'Twitter',
      icon: 'el-icon-orange'
    },
    children: [
      {
        // alias: '/',
        path: 'account',
        name: 'TwitterAccount',
        component: () => import('@/views/twitter/account.vue'),
        meta: {
          title: '推特帐号',
          icon: 'el-icon-user'
        }
      },
      {
        path: 'submission',
        name: 'submission',
        component: () => import('@/views/twitter/submission.vue'),
        meta: {
          title: '账号提交',
          icon: 'el-icon-news'
        }
      },
      {
        path: 'postTask',
        name: 'TwitterPostTask',
        component: () => import('@/views/twitter/postTask.vue'),
        meta: {
          title: '推特发帖',
          icon: 'el-icon-tickets'
        }
      },
      {
        path: 'forwardTask',
        name: 'TwitterForwardTask',
        component: () => import('@/views/twitter/forwardTask.vue'),
        meta: {
          title: '推特转帖',
          icon: 'el-icon-s-promotion'
        }
      },
      {
        path: 'likeTask',
        name: 'TwitterLikeTask',
        component: () => import('@/views/twitter/likeTask.vue'),
        meta: {
          title: '推特点赞',
          icon: 'el-icon-aim'
        }
      },
      {
        path: 'commentTask',
        name: 'TwitterCommentTask',
        component: () => import('@/views/twitter/commentTask.vue'),
        meta: {
          title: '推特评论',
          icon: 'el-icon-chat-line-square'
        }
      },
      {
        path: 'report',
        name: 'TwitterReport',
        component: () => import('@/views/twitter/report.vue'),
        meta: {
          title: '推特举报',
          icon: 'el-icon-thumb'
        }
      },
      {
        path: 'batchUpload',
        name: 'TwitterBatchUpload',
        component: () => import('@/views/twitter/batchUpload.vue'),
        meta: {
          title: '批量上传',
          icon: 'el-icon-upload'
        }
      }
    ]
  },
  {
    path: '/facebook',
    component: Layout,
    // alias: '/twitter',
    name: 'Facebook',
    redirect: '/facebook/account',
    meta: {
      title: 'Facebook',
      icon: 'el-icon-orange'
    },
    children: [
      {
        // alias: '/',
        path: 'account',
        name: 'FacebookAccount',
        component: () => import('@/views/facebook/account.vue'),
        meta: {
          title: '脸书帐号',
          icon: 'el-icon-user'
        }
      },
      {
        path: 'postTask',
        name: 'FacebookPostTask',
        component: () => import('@/views/facebook/postTask.vue'),
        meta: {
          title: '脸书发帖',
          icon: 'el-icon-tickets'
        }
      }
    ]
  },
  {
    path: '/youTube',
    component: Layout,
    // alias: '/twitter',
    name: 'YouTube',
    redirect: '/youTube/account',
    meta: {
      title: 'YouTube',
      icon: 'el-icon-orange'
    },
    children: [
      {
        // alias: '/',
        path: 'account',
        name: 'YouTubeAccount',
        component: () => import('@/views/youTube/account.vue'),
        meta: {
          title: '油管帐号',
          icon: 'el-icon-user'
        }
      },
      {
        path: 'postTask',
        name: 'YouTubePostTask',
        component: () => import('@/views/youTube/postTask.vue'),
        meta: {
          title: '油管发布',
          icon: 'el-icon-tickets'
        }
      }
    ]
  },
  {
    path: '/label',
    component: Layout,
    // alias: '/twitter',
    name: 'label',
    redirect: '/label/index',
    meta: {
      title: '标签管理',
      icon: 'el-icon-star-off'
    },
    children: [
      {
        // alias: '/',
        path: 'index',
        name: 'labelIndex',
        component: () => import('@/views/label/Index.vue'),
        meta: {
          title: '标签',
          icon: 'el-icon-s-flag'
        }
      },
      {
        path: 'facebook',
        name: 'label_facebook',
        component: () => import('@/views/label/Facebook.vue'),
        meta: {
          title: 'facebook账号标记',
          icon: 'el-icon-magic-stick'
        }
      },
      {
        path: 'twitter',
        name: 'label_twitter',
        component: () => import('@/views/label/Twitter.vue'),
        meta: {
          title: 'twitter账号标记',
          icon: 'el-icon-magic-stick'
        }
      },
      {
        path: 'youtube',
        name: 'label_youtube',
        component: () => import('@/views/label/YouTube.vue'),
        meta: {
          title: 'youtube账号标记',
          icon: 'el-icon-magic-stick'
        }
      }
    ]
  },
  {
    path: '/group',
    component: Layout,
    // alias: '/twitter',
    name: 'group',
    redirect: '/group/index',
    meta: {
      title: '分组管理',
      icon: 'el-icon-orange'
    },
    children: [
      {
        // alias: '/',
        path: 'index',
        name: 'groupIndex',
        component: () => import('@/views/group/Index.vue'),
        meta: {
          title: '分组',
          icon: 'el-icon-user'
        }
      },
      {
        // alias: '/',
        path: 'detail/:group',
        name: 'groupDetail',
        hidden: true,
        component: () => import('@/views/group/GroupDetail.vue'),
        meta: {
          title: '分组详情',
          icon: 'el-icon-user'
        }
      }
    ]
  },
  // 订单信息
  {
    path: '/service',
    component: Layout,
    name: 'Service',
    redirect: '/service/shoppingList',
    meta: {
      title: '购买服务',
      icon: 'el-icon-s-shop'
    },
    children: [
      {
        path: 'shoppingList',
        name: 'ShoppingList',
        component: () => import('@/views/service/shoppingList.vue'),
        meta: {
          title: '服务列表',
          icon: 'el-icon-goods'
        }
      },
      {
        name: 'PointsMenu',
        path: 'pointsMenu',
        component: () => import('@/views/service/pointsMenu.vue'),
        meta: {
          title: '积分记录',
          icon: 'el-icon-coin'
        }
      },
      {
        name: 'OrderList',
        path: 'orderList',
        component: () => import('@/views/service/orderList.vue'),
        meta: {
          title: '我的订单',
          icon: 'el-icon-shopping-cart-full'
        }
      }
    ]
  },
  {
    name: 'Material',
    path: '/material',
    component: Layout,
    redirect: '/material/myMaterial',
    meta: {
      title: '素材库',
      icon: 'el-icon-s-cooperation'
    },
    children: [
      {
        name: 'MyMaterial',
        path: 'myMaterial',
        component: () => import('@/views/service/material.vue'),
        meta: {
          title: '我的素材',
          icon: 'el-icon-shopping-cart-full'
        }
      },
      {
        name: 'MaterialUpload',
        path: 'materialUpload',
        component: () => import('@/views/service/materialUpload.vue'),
        meta: {
          title: '素材上传',
          icon: 'el-icon-upload2'
        }
      },
      {
        name: 'language',
        path: 'language',
        component: () => import('@/views/service/language.vue'),
        meta: {
          title: '语料',
          icon: 'el-icon-magic-stick'
        }
      }
    ]
  },
  {
    name: 'Manage',
    path: '/manage',
    component: Layout,
    redirect: '/manage/userSettings',
    meta: {
      title: '管理员',
      icon: 'el-icon-s-custom'
    },
    children: [
      // {
      //   name: 'TwitterDemoAccount',
      //   path: 'twitterDemoAccount',
      //   component: () => import('@/views/manage/twitterDemoAccount.vue'),
      //   meta: {
      //     title: '推特演示帐号',
      //     icon: 'el-icon-female',
      //   },
      // },
      // {
      //   name: 'FacebookDemoAccount',
      //   path: 'facebookDemoAccount',
      //   component: () => import('@/views/manage/facebookDemoAccount.vue'),
      //   meta: {
      //     title: '脸书演示帐号',
      //     icon: 'el-icon-female',
      //   },
      // },
      // {
      //   name: 'YouTubeDemoAccount',
      //   path: 'youTubeDemoAccount',
      //   component: () => import('@/views/manage/youTubeDemoAccount.vue'),
      //   meta: {
      //     title: '油管演示帐号',
      //     icon: 'el-icon-female',
      //   },
      // },
      {
        name: 'UserSettings',
        path: 'userSettings',
        component: () => import('@/views/manage/userSettings.vue'),
        meta: {
          title: '用户管理',
          icon: 'el-icon-s-custom'
        }
      },
      {
        name: 'RiskKeyword',
        path: 'RiskKeyword',
        component: () => import('@/views/manage/RiskKeyword.vue'),
        meta: {
          title: '风险配置',
          icon: 'el-icon-goods'
        }
      },
      {
        name: 'Proxy',
        path: 'Proxy',
        component: () => import('@/views/manage/proxy.vue'),
        meta: {
          title: '线路配置',
          icon: 'el-icon-goods'
        }
      },
      {
        name: 'TwitterAccountOrder',
        path: 'TwitterAccountOrder',
        component: () => import('@/views/manage/twitterAccountOrder.vue'),
        meta: {
          title: 'twitter帐号配置',
          icon: 'el-icon-goods'
        }
      },
      {
        name: 'ShoppingDetails',
        path: 'ShoppingDetails',
        component: () => import('@/views/manage/ShoppingDetails.vue'),
        meta: {
          title: '服务详情',
          icon: 'el-icon-goods'
        }
      },
      {
        name: 'manageOrderList',
        path: 'manageOrderList',
        component: () => import('@/views/manage/manageOrderList.vue'),
        meta: {
          title: '订单详情',
          icon: 'el-icon-shopping-cart-2'
        }
      },
      {
        name: 'pointsMenu',
        path: 'pointsMenu',
        component: () => import('@/views/service/pointsMenu.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/login/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
