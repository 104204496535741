import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 // request timeout
})

export function getCookie(cookiename) {
  var allcookies = document.cookie
  // 索引长度，开始索引的位置
  var cookiepos = allcookies.indexOf(cookiename)

  // 如果找到了索引，就代表cookie存在,否则不存在
  if (cookiepos !== -1) {
    // 把cookiepos放在值的开始，只要给值加1即可
    // 计算取cookie值得开始索引，加的1为“=”
    cookiepos = cookiepos + cookiename.length + 1

    // 计算取cookie值得结束索引
    var cookieend = allcookies.indexOf(';', cookiepos)

    if (cookieend === -1) {
      cookieend = allcookies.length
    }
    // 得到想要的cookie的值
    // var value = unescape(allcookies.substring(cookiepos, cookieend))
    var value = allcookies.substring(cookiepos, cookieend)
  }
  return value
}

// // request interceptor
service.interceptors.request.use(
  config => {
    config.headers['X-CSRFToken'] = getCookie('csrftoken')
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { status } = response
    const { message, data } = response.data
    switch (status) {
      // case 400:
      //   Message.error(data.message)
      //   return Promise.reject(new Error(data.message))
      // case 401:
      //   console.log('401', status)
      //   Message.warning('登录状态失效,请重新登录')
      //   // eslint-disable-next-line no-case-declarations
      //   const origin = window.localStorage.getItem('origin')
      //   if (typeof origin === 'undefined' || origin == null || origin === '') {
      //     Router.push({ path: '/login' })
      //   } else {
      //     window.location.href = origin
      //   }
      //   return Promise.reject(new Error(data.message))
      // case 403:
      //   Message.error(data.message)
      //   return Promise.reject(new Error(data.message))
      // case 404:
      //   Message.error(data.message)
      //   return Promise.reject(new Error(data.message))
      case 200:
        // Message.success(message)
        return data
      case 201:
        Message.success(message)
        return data
      case 204:
        Message.success('删除成功')
        return data
      default:
        Message.error(message)
        return data
    }
  },
  error => {
    const { status } = error.response
    const { message, data } = error.response.data
    console.log('失败响应状态', error.response.data, status)
    switch (status) {
      case 400:
        if (data === null || data === undefined) {
          Message.error(message)
        } else {
          for (var errorfield in data) {
            const errorinfos = data[errorfield]
            for (var errorinfoindex in errorinfos) {
              Message.error(errorfield + ': ' + errorinfos[errorinfoindex])
            }
          }
        }
        return Promise.reject(new Error(data))
      case 401:
        console.log('401', status)
        Message.warning(message)
        // eslint-disable-next-line no-case-declarations
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          Router.push({ path: '/login' })
        } else {
          window.location.href = origin
        }
        return Promise.reject(new Error(data))
      case 403:
        Message.error(message)
        return Promise.reject(new Error(data))
      case 404:
        Message.error('接口不存在')
        return Promise.reject(new Error(data))
      case 405:
        Message.error('不允许的请求方式')
        return Promise.reject(new Error(data))
    }
    console.log(error)
  }
)

export default service
