import Vue from 'vue'
import App from './App.vue'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import router from './router'
import store from './store'
import translatePage from '@/components/material/TranslateDialog'
// import VueVideoPlayer from 'vue-video-player'

// // require videojs style
// import 'video.js/dist/video-js.css'
import './plugins/element.js'
import '@/styles/index.scss' // global css
// 导入全局样式表
// import './assets/global.css'
import '@/icons' // icon
Vue.config.productionTip = false

Vue.component('translatePage', translatePage)
new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
