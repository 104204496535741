<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }}</h1> -->
        <span class="only-logo">
          Z
        </span>
      </router-link>

      <div v-else class="show-sidebar">
        <router-link to="/">
          <div class="show-sidebar-title">{{ title }}</div>
          <div class="title-assistant">(原媒体自动化系统)</div>
        </router-link>
        <div class="show-sidebar-info">
          <img :src="logo" alt="" />
          <div class="info-name">
            {{
              info.oauth_account && info.oauth_account !== ''
                ? info.oauth_account
                : info.username
            }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import touPic0 from '@/assets/toupic/tou0.jpg'
import touPic1 from '@/assets/toupic/tou1.jpg'
import touPic2 from '@/assets/toupic/tou2.jpg'
import touPic3 from '@/assets/toupic/tou3.jpg'
import touPic4 from '@/assets/toupic/tou4.jpg'
import touPic5 from '@/assets/toupic/tou5.jpg'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '账号运营系统',
      logo: ''
    }
  },
  computed: {
    info() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    const id = parseInt(JSON.parse(sessionStorage.getItem('userInfo')).id)
    this.picNum(id)
    // console.log(id)
  },
  methods: {
    picNum(id) {
      const num = id % 5
      if (num === 0) {
        this.logo = touPic0
      } else if (num === 1) {
        this.logo = touPic1
      } else if (num === 2) {
        this.logo = touPic2
      } else if (num === 3) {
        this.logo = touPic3
      } else if (num === 4) {
        this.logo = touPic4
      } else {
        this.logo = touPic5
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}
.only-logo {
  font-size: 28px;
  font-weight: 600;
  color: white;
  font-style: italic;
  &:hover {
    color: rgb(228, 194, 194);
  }
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;

  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    line-height: 50px;
    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
  .show-sidebar {
    a {
      display: inline !important;
    }
    width: 100%;
    // background: #cb3700;
    .show-sidebar-title {
      width: 100%;
      // min-height: 60px;
      text-align: center;
      padding-top: 15px;
      // background: #cb3700;
      font-size: 22px;
      font-weight: bold;
      color: #f9f9f9;
    }
    .title-assistant {
      width: 100%;
      margin: 5px 0 10px 0;

      font-size: 12px;
      color: white;
      text-align: center;
    }
    .show-sidebar-info {
      width: calc(100% - 10px);
      padding: 16px 0;
      height: 168px;
      background: #537c4c;
      margin: 0 auto;
      text-align: center;
      img {
        margin-top: 10px;
        width: 74px;
        height: 74px;
        object-fit: cover;
        border-radius: 50%;
      }
      .info-name {
        height: auto !important;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        margin: 5px 0;
      }
      .info-type {
        font-size: 14px;
        height: auto !important;
        color: #89898a;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
